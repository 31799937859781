<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button class="ml-1" @click="goBack()" size="sm" variant="primary"
        >Back</b-button
      >
    </div>
    <validation-observer ref="GroupAddForm">
      <b-form class="auth-login-form mt-2" @submit.prevent="addEmail">
        <!-- email -->
        <b-form-group label="Email" label-for="email">
          <template #label>
            <span>Email</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <b-form-input id="email" v-model="email" name="email" />
        </b-form-group>
        <!-- <b-form-group label="Domain" label-for="domain">
          <template #label>
            <span>Domain</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <b-form-input id="domain" v-model="domain" name="domain" />
        </b-form-group> -->
        <b-button
          type="submit"
          variant="primary"
          :disabled="email === '' || isdisable"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Add</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    flatPickr,
    BSpinner,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      super_admin: this.$store.state.app.user.id == 1,
      project_name: "",
      scanId: "",
      domain: "",
      email: "",
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      // organization: this.$store.state.app.org_id,
      // organization: "",
      // organizations: [],
      org_id: this.$store.state.app.organizationId,
      selectedOrganization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.organizations,
      isloading: false,
      isdisable: false,
    };
  },
  computed: {
    organization() {
      return this.organizations.find(
        (org) => org.value === this.$store.state.app.org_id
      );
    },
    isOppositeCheckboxDisabled() {
      return (oppositeCheckboxState) => {
        return oppositeCheckboxState;
      };
    },
    selectedOrganizationRefid() {
      if (this.organization) {
        return this.organization.value;
      }
      return null; // Handle the case when organization is not selected
    },
    selectedOrganizationId() {
      if (!this.super_admin) {
        return this.$store.state.app.organizationId;
      } else if (this.organization) {
        return this.organization.id;
      }
      return null;
    },
  },
  created: function () {
    // this.load();
    this.onOrgChange();
  },
  methods: {
    onOrgChange: function () {
      this.users = [];
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.selectedOrganizationRefid;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].username,
          };
          // console.log(res.data[key].profile.organization)
          self.users.push(user);
        });
      });
    },
    addEmail: function () {
      this.$refs.GroupAddForm.validate().then((success) => {
        this.isdisable = true;
        this.isloading = true;
        if (success) {
          //call to axios
          var self = this;
          let data = {
            email: this.email,
            // domain: this.domain,
            org_id: this.org_id,
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "asset-discovery/public-emails/",
          };
          var self = this;
          this.$http(options).then((res) => {
            this.isloading = false;
            this.isdisable = false;
            // this.$router.push({ name: "Email Harvest" });
            this.$router.push({
              name: "Discovery List",
              query: {
                tabIndex: 2,
              },
            });
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added Email!",
                icon: "EditIcon",
                variant: "success",
              },
            });
          });
        }
      });
    },
    goBack() {
      this.$router.push({
        name: "Discovery List",
        query: {
          tabIndex: 2,
        },
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>